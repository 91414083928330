<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Dokumente</li>
            </ul>
            <div class="header-actions">

            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li class="active"><router-link to="/admin/documents/">Rechnungen</router-link></li>
          </ul>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div v-if="search_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="10%">Nummer</th>
                      <th width="10%">Datum</th>
                      <th width="30%">Name</th>
                      <th width="10%">Betrag</th>
                      <th width="20%">Details</th>
                      <th width="20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoice in invoices" v-bind:key="invoice.id">
                      <td>
                        <span v-if="invoice.canceled" style="text-decoration: line-through;">000{{ invoice.number }}</span>
                        <span v-else>000{{ invoice.number }}</span>
                      </td>
                      <td>
                        <span v-if="invoice.canceled" style="text-decoration: line-through;">{{ invoice.date_formatted }}</span>
                        <span v-else>{{ invoice.date_formatted }}</span>
                      </td>
                      <td>
                        <span v-if="invoice.canceled" style="text-decoration: line-through;">
                          <span v-if="invoice.company">{{ invoice.company }}<br></span>
                          {{ invoice.first_name }} {{ invoice.last_name }}
                        </span>
                        <span v-else>
                          <span v-if="invoice.company">{{ invoice.company }}<br></span>
                          {{ invoice.first_name }} {{ invoice.last_name }}
                        </span>
                      </td>
                      <td>
                        <span v-if="invoice.canceled" style="text-decoration: line-through;">{{ parseFloat(invoice.sum_gross).toFixed(2) }} €</span>
                        <span v-else>{{ parseFloat(invoice.sum_gross).toFixed(2) }} €</span>
                      </td>
                      <td>
                        <span v-if="invoice.canceled" style="text-decoration: line-through;">
                          <span v-if="invoice.reservation">Reservierung: {{ invoice.reservation.id }}</span>
                          <span v-if="invoice.damage">Schaden: {{ invoice.damage.id }}</span>
                        </span>
                        <span v-else>
                          <span v-if="invoice.reservation">Reservierung: {{ invoice.reservation.id }}</span>
                          <span v-if="invoice.damage">Schaden: {{ invoice.damage.id }}</span>
                        </span>
                      </td>
                      <td style="text-align:right">
                        <router-link v-if="user.admin" title="Provisionszeitraum" :to="'/admin/documents/'+invoice.id">
                          <span v-if="invoice.commission_year && invoice.commission_month" class="material-icons" style="color:green">credit_card</span>
                          <span v-else class="material-icons" style="color:#FF9000">credit_card</span>
                        </router-link>
                        <router-link v-if="invoice.canceled == true" :to="'/invoices/'+invoice.uuid+'?canceled=true'" style="margin-left:15px;"><span class="material-icons">receipt</span></router-link>
                        <router-link v-if="invoice.canceled == false" :to="'/invoices/'+invoice.uuid" style="margin-left:15px;"><span class="material-icons">receipt</span></router-link>
                        <a v-if="invoice.canceled == false" @click="cancel_invoice(invoice.id)" style="margin-left:15px;"><span class="material-icons">cancel</span></a>
                        <a v-if="user.admin" @click="delete_invoice(invoice.id)" style="margin-left:15px;"><span class="material-icons">delete</span></a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="pagination.links">
                  <div class="row row-gutter-20">
                    <div class="col-12" style="text-align:left">
                      <p><a v-if="pagination.links.prev" @click="next_page(pagination.links.prev)">‹ Vorherige Seite</a></p>
                    </div>
                    <div class="col-12" style="text-align:right">
                      <p><a v-if="pagination.links.next" @click="next_page(pagination.links.next)">Nächste Seite ›</a></p>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_documents',
  components: {
    AdminNav
  },
  data () {
    return {
      loading: true,
      search_phrase: [],
      search_loading: false,
      invoices: [],
      pagination: {},
      user: {}
    }
  },
  methods: {
    get_data(page) {
      axios.get(process.env.VUE_APP_BASE_API+page, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.invoices = response.data.invoices;
        this.pagination = response.data.meta.pagination;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })
      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.user = response.data.user;
      })
    },
    get_locations() {},
    delete_invoice(id) {
      if (confirm("Jetzt löschen?")) {
        axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/invoices/'+id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.loading = true;
          this.get_data('/v1/admin/invoices?page=1');
        })
      }
    },
    cancel_invoice(id) {
      if (confirm("Jetzt stornieren?")) {
        axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/invoices/'+id, {
          'canceled': true
        }, { headers: { Authorization: this.$store.getters.getToken }})
        .then(() => {
          this.loading = true;
          this.get_data('/v1/admin/invoices?page=1');
        })
      }
    },
    next_page(page) {
      this.loading = true;
      this.get_data(page);
    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/invoices?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.invoices = response.data.invoices;
          this.pagination = response.data.meta.pagination;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    },
  },
  mounted () {
    this.get_data('/v1/admin/invoices?page=1');
  }
}
</script>
